/* import rubik font style */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&family=Rubik:wght@300&display=swap");

@font-face {
  font-family: "MOONFARK";
  src: url('../public/assets/fonts/MOONFARK.ttf');
}

* {
  box-sizing: border-box;
  user-select: none;
}
body {
  max-width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


